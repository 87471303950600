





































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { mdiMagnify, mdiInformationOutline } from "@mdi/js";

import {
  FETCH_FILTERED_RESOURCES_NAME,
  GET_FILTERED_RESOURCES_NAME,
  FETCH_AND_RETURN_RESOURCE_PRIVILEGES_YAML,
} from "@/store/acdb";
import i18n from "@/i18n";

export default Vue.extend({
  name: "UserPrivilageTable",
  mounted() {
    this.fetchFilteredResourcesName();
  },
  data() {
    return {
      search: "",
      mdiMagnify,
      mdiInformationOutline,
      filteredResourcesName: [] as any[],
      isResourcePrivilegesDisplayed: false,
      resourceName: "",
      resourcePrivileges: "",
      itemsPerPage: 5,
    };
  },
  methods: {
    ...mapActions({
      fetchFilteredResourcesName: FETCH_FILTERED_RESOURCES_NAME,
      fetchAndReturnResourcePrivilegesYAML: FETCH_AND_RETURN_RESOURCE_PRIVILEGES_YAML,
    }),
    async displayResourcePrivileges(resourceName: string) {
      this.resourceName = resourceName;
      this.resourcePrivileges = await this.fetchAndReturnResourcePrivilegesYAML(
        resourceName
      );
      this.isResourcePrivilegesDisplayed = true;
    },
    hideResourcePrivileges() {
      this.resourceName = "";
      this.resourcePrivileges = "";
      this.isResourcePrivilegesDisplayed = false;
    },
    filterSearchResources(newFilterSearch: string) {
      this.filterResources(newFilterSearch);
    },
    filterResources(filterSearch: string) {
      this.fetchFilteredResourcesName(filterSearch);
    },
    filterResourcesOnKey(event: any) {
      if (["Tab", "Enter"].includes(event.key))
        this.filterResources(this.search);
    },
    clearSearch() {
      this.search = "";
      this.filterResources(this.search);
    },
    hasResources() {
      return this.filteredResourcesName.length === 0;
    },
  },
  computed: {
    ...mapGetters({
      getFilteredResourcesName: GET_FILTERED_RESOURCES_NAME,
    }),
    searchLabel: () => i18n.t("search"),
  },
  watch: {
    getFilteredResourcesName: function(
      newResourcesName: string[],
      oldResourcesName: string[]
    ) {
      this.filteredResourcesName = newResourcesName.map(
        (resourceName, index) => {
          return { id: index, name: resourceName };
        }
      );
    },
  },
});
















































































// node_modules
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { mdiLeadPencil, mdiInformationOutline } from '@mdi/js';

import UserEditRoleCard from '@/components/UserEditRoleCard.vue';

// Store's functions
import { GET_ROLES, FETCH_ROLES } from '@/store/roles';

import { FETCH_AND_RETURN_ROLE_PRIVILEGES_YAML } from '@/store/acdb';

interface Data {
  editIndex: null | number | any;
  isRolePrivilegesDisplayed: boolean;
  mdiLeadPencil: string;
  mdiInformationOutline: string;
  rolePrivileges: null | string | any;
  offsetX: boolean;
}

export default Vue.extend({
  name: 'UserEditRoleList',

  components: {
    UserEditRoleCard,
  },
  mounted() {
    this.fetchRoles();
  },
  data(): Data {
    return {
      mdiLeadPencil,
      mdiInformationOutline,
      editIndex: null,
      isRolePrivilegesDisplayed: false,
      rolePrivileges: [],
      offsetX: true,
    };
  },
  methods: {
    ...mapActions({
      fetchRoles: FETCH_ROLES,
      fetchRolePrivileges: FETCH_AND_RETURN_ROLE_PRIVILEGES_YAML,
    }),
    quitRoleEdit() {
      this.editIndex = null;
      this.fetchRoles();
    },
    edit(index: Number) {
      this.editIndex = index;
    },
    async displayRolePrivileges(roleID: number, roleName: string) {
      this.rolePrivileges = await this.fetchRolePrivileges({
        roleID,
        roleName,
      });
      this.isRolePrivilegesDisplayed = true;
    },
    hideRolePrivileges() {
      this.rolePrivileges = '';
      this.isRolePrivilegesDisplayed = false;
    },
  },
  computed: {
    ...mapGetters({
      allRoles: GET_ROLES,
    }),
  },
});
